import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
      &.txt-color-red {
        color: #ff6969;
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0051 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="同一労働同一賃金の原則について。メリット・デメリットを解説" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2023.5.29</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        同一労働同一賃金に関する法施行がされた現代においても、コロナ対応によって、同一労働同一賃金対応への優先順位が下がっている業種があることは否めません。しかし、何も対応しないということは不適切であり、いずれ終息するコロナ後の労務管理を見据えて、対応が必要となります。今回は同一労働同一賃金の原則に立ち返り、創設された背景、メリットとデメリットにフォーカスをあて、解説します。
      </p>
      <img src="/images/column/details/c0051.jpg" alt="キンクラコラム画像51" />
      <h1 id="toc1">同一労働同一賃金が創設された背景</h1>
      <p>
        2019年に統計開始後初めて出生数が90万人を下回り、その後も回復はおろか、下降が続いている状況です。この数字が示す意味として、近い将来、現状よりも更に働く人の数が減ることを示唆しています。働く人の数が減るとなれば、人手不足がより深刻になり、国力の低下が避けられなくなります。そうなれば、全員、正社員で募集をかけて新たに人を雇用すれば問題は解決するのではないかとの声もありますが、子育て（例えば子の出生後小学校就学の始期に達するまでの子を養育する労働者）や体力的に不安のある労働者（例えば高年齢労働者）は必ずしも正社員雇用を望んでいるわけではありません。正社員となれば非正規社員と比べて、拘束時間が長くなること、責任の程度も重くなることは至極当然です。よって、むしろ短時間での勤務を望むという労働者は少なくありません。これは、2019年4月1日より段階的に進められている働き方改革により、「多様な働き方」への尊重も社会的に拍車をかけ、様々な雇用形態での就労が注目されています。
        <br />
        <br />
        しかし、担っている仕事の内容や責任の程度が正社員とほとんど変わらないにも関わらず、「非正規社員だから」という理由で給与が低いとなれば、感情を持つ人間として就労意欲が減退することは想像に難くありません。そこで、
        <span>
          「不合理な賃金格差」を是正すべく、国を挙げて、雇用形態が異なっていても、同じ仕事をしているのであれば同じ賃金を支払うべき
        </span>
        という改革に取り組むこととなりました。
      </p>

      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}

      <h1 id="toc2">同一労働同一賃金のメリット</h1>
      <p>
        まずは、労働者側のメリットとして、
        <span>非正規労働者の就労意欲の向上</span>
        が挙げられます。評価は全て賃金に紐づけるべきとは言えませんが、賃金は会社からの評価の一指標と考えるのが自然です。また、就労意欲が向上することで、
        <span>新たなスキル習得のための能力開発に繋がる期待</span>
        も持てます。併せて、非正規雇用労働者が適正に評価されていると認識できることで、離職率の低下も想定されます。離職者が出て、新たに求人をかけた後に、採用・育成となれば一定の労力と人件費の支出は必要です。これは離職者が出ていなければ本来発生しなかった支出ですので、無視できない部分です。平均勤続年数が伸びることにより（退職金を支払う会社であれば退職金が増えるという見方もできなくはありませんが）組織としての人的サービスがより強固になり、
        <span>顧客満足度のアップ</span>も期待できます。
        <br />
        <br />
        また、会社側のメリットとして、あえて差を設けるのであれば、
        <span>雇用形態別に担ってもらう業務の範囲、責任の程度が整理できる</span>
        というメリットがあります。個人事業と異なり、組織で業務を回していく場合には、誰がどの業務を担い、問題発生時に誰がどの範囲まで責任を負うのかを明確化しておくことで、新たな業務を受注した場合や、トラブル発生時の初動対応が早くなります。
      </p>

      <h1 id="toc3">同一労働同一賃金のデメリット</h1>
      <p>
        まず、労働者側のデメリットとして、会社として正社員と同等程度の賃金（仕事の内容等が同じ場合）を支払う体力がない場合、契約更新をしない（またはできない）といった連鎖が予想されます。慈善事業でないかぎり、企業は一定の利益を出していかなければなりません。しかし、多数の非正規雇用労働者を雇用している場合は就労意欲の向上以前に、会社の資金力が枯渇してしまうリスクも否定できません。一度賃金をアップさせた後に引き下げるという対応は取りづらいために、
        <span>
          法令が求める対応ができないと悟った場合に、更新をしないとった対応が予想されます。
        </span>
        <br />
        <br />
        次に会社側のデメリットとして、人件費の高騰は避けることができません。もちろん、メリットで挙げた就労意欲の向上により、会社として生産性が高くなったことで、長期的にはむしろプラスになるのではないかとの声もあります。しかし、例外なく一朝一夕にそのレベルまで到達できるとは言えませんし、少なくとも短期的には人件費が高騰することは避けられません。
      </p>

      <h1 id="toc4">最後に</h1>
      <p>
        同一労働同一賃金の背景、メリット・デメリットを述べてきました。まずはメリットとして挙げた就労意欲の向上や能力開発はあくまで抽象的な期待です。反対にデメリットで挙げた人件費の増加は、対応すると決断した時点で数字として如実に表れます。よって、双方を総合的に勘案するとデメリットの方が大きいのではないかとの声があります。しかし、会社にとって重要な資源に「人・物・金」がありますが、「人」については唯一「感情」を持っています。
        <span>「感情」はプラスにもマイナスにも進むことがあります。</span>
        いきなり他社の成功事例を参考にするべきではありませんが、法令で明記された以上、可能な限り「プラス」に進むような（自社にとっての）同一労働同一賃金施策をすすめていきましょう。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0051
